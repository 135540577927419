<div class="container-fluid">
    <div class="row">
        <div class="col">
            <img src="assets/logo-background.png" class="img-fluid" />
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <h1>Coming soon</h1>
        </div>
    </div>
</div>
